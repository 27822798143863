define('ember-cli-geo/services/geolocation', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Service.extend(_ember['default'].Evented, {

    geolocator: _ember['default'].computed(function () {
      return window.navigator.geolocation;
    }),

    _handleNewPosition: function _handleNewPosition(geoObject) {
      this.set('currentLocation', [geoObject.coords.latitude, geoObject.coords.longitude]);
      var callback = this.get('trackingCallback');
      if (callback) {
        callback(geoObject);
      }
      this.trigger('geolocationSuccess', geoObject);
    },

    currentLocation: null,

    getLocation: function getLocation(geoOptions) {
      var _this = this;

      return new _ember['default'].RSVP.Promise(function (resolve, reject) {
        _this.get('geolocator').getCurrentPosition(function (geoObject) {
          _this._handleNewPosition(geoObject);
          resolve(geoObject);
        }, function (reason) {
          _this.trigger('geolocationFail', reason);
          reject(reason);
        }, geoOptions);
      });
    },

    trackLocation: function trackLocation(geoOptions, callback) {
      var _this2 = this;

      var watcherId = this.get('watcherId');

      _ember['default'].assert(watcherId == null, 'Warning: `trackLocation` was called but a tracker is already set');

      if (callback != null) {
        _ember['default'].assert(typeof callback === 'function', "callback should be a function");
      }
      this.set('trackingCallback', callback);

      return new _ember['default'].RSVP.Promise(function (resolve, reject) {
        var watcherId = _this2.get('geolocator').watchPosition(function (geoObject) {
          // make sure this logic is run only once
          if (resolve) {
            _this2.set('watcherId', watcherId);
            resolve(geoObject);
            resolve = null;
          }
          _this2._handleNewPosition(geoObject);
        }, function (reason) {
          _this2.trigger('geolocationFail', reason);
          reject(reason);
        }, geoOptions);
      });
    },

    stopTracking: function stopTracking(clearLocation) {
      var watcher = this.get('watcherId');
      _ember['default'].assert(watcher != null, 'Warning: `stopTracking` was called but location isn\'t tracked');
      this.get('geolocator').clearWatch(watcher);
      this.set('watcherId', null);
      if (clearLocation === true) {
        this.set('currentLocation', null);
      }
    }

  });
});