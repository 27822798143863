define('ember-cli-google-recaptcha/components/g-recaptcha-v2', ['exports', 'ember-cli-google-recaptcha/-private/g-recaptcha-base'], function (exports, _gRecaptchaBase) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _gRecaptchaBase.default.extend({
    theme: 'light',

    type: 'image',

    size: 'normal'
  });
});