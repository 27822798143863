define('ember-keyboard-shortcuts/create-mixin', ['exports', 'ember-keyboard-shortcuts/index'], function (exports, _index) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  exports.default = function (bindEvent, unbindEvent) {
    return Ember.Mixin.create({
      init() {
        this._super(...arguments);

        Ember.deprecate('Using ember-keyboard-shortcuts Mixin is deprecated. ' + 'Please use bindKeyboardShortcuts and unbindKeyboardShortcuts methods instead. ' + 'Usage of mixins will be removed in the next major release. ' + 'Learn more about migrating at https://github.com/Skalar/ember-keyboard-shortcuts#migrating-from-mixins', false, {
          id: 'ember-keyboard-shortcuts.mixins',
          until: '2.0.0'
        });
      },

      bindShortcuts: Ember.on(bindEvent, function () {
        (0, _index.bindKeyboardShortcuts)(this);
      }),

      unbindShortcuts: Ember.on(unbindEvent, function () {
        (0, _index.unbindKeyboardShortcuts)(this);
      })
    });
  };
});